body {
  margin: 0;
  padding: 0;
  color: var(--primary-font-color, #111b32);
  background-color: var(--primary-bg-color, #f9f9f9);
}

a {
  color: var(--primary-font-color, #111b32);
  text-decoration: none;
}

button,
a,
img {
  outline:0;
}

.not-loaded {
  overflow: hidden;
}

.not-loaded #root {
  opacity: 0;
  transition: opacity 0s;
}

#root {
  opacity: 1;
  transition: opacity 0.3s;
}

